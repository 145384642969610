import React from 'react';

import '../home/home.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Card } from 'react-bootstrap';

import imgExperiencia from "../../assets/img/webp/banner-marketing-digital.webp";
import imgUx1 from "../../assets/img/webp/img-ux-001.webp";
import imgUx2 from "../../assets/img/webp/img-ux-006.webp";
import imgUx3 from "../../assets/img/webp/img-ux-003.webp";
import imgUx4 from "../../assets/img/webp/img-ux-004.webp";
import imgSeguridad from "../../assets/img/webp/img-seguridad.webp";
import { FormContact2 } from '../../component/form/FormContact2';
import Slider from '../../component/slider/Slider';
import iconServicios1 from "../../assets/img/webp/ico-carpinteria.webp";
import iconServicios2 from "../../assets/img/webp/ico-dns.webp";
import iconServicios3 from "../../assets/img/webp/ico-objetivo.webp";
import iconServicios4 from "../../assets/img/webp/ico-apoyo-tecnico.webp";
import iconServicios5 from "../../assets/img/webp/ico-documento.webp";
import iconServicios6 from "../../assets/img/webp/ico-almacenamiento.webp";
import iconServicios7 from "../../assets/img/webp/ico-retroalimentacion.webp";
import iconServicios8 from "../../assets/img/webp/ico-negocio.webp";
import Contacto from '../Contacto/Contacto';
import SliderItems from '../../component/slider/SliderItems';
import InfographicResponsive from '../../component/infographics/InfographicResponsive';
import UIButton from '../../component/atoms/UIButton/UIButton';
import CardService from '../../component/cards/CardService';
import CardTest from '../../component/cards/CardTest';
import SliderClients from '../../component/slider/SliderClients';
import CardNewsLetter from '../../component/cards/CardNewsLetter';
import youtube from "../../assets/img/webp/youtube.webp";
import twitter from "../../assets/img/webp/twitter.webp";
import linkedin from "../../assets/img/webp/linkedin.webp";
import robotSearch from "../../assets/img/webp/robot_search.webp";
import robotDog from "../../assets/img/webp/robot_dog.webp";
 
export default function Home() {

    return (
        <>
            <Container fluid className='p-0 contBg'>
                {/* carousel */}
                <Row className='w-100 p-0 m-0 justify-content-center align-items-center contCarousel' id='carousel'>
                    <Col className='col-12 mb-3'>
                        <Slider />
                    </Col>
                </Row>    
                {/* cotizador */}
                <Row className='w-100 p-0 m-0 justify-content-center' id='cotizador'>                    
                    <Col className='col-11 col-md-8 text-center contCotizador'>
                        <Row className='w-100 p-0 m-0 justify-content-center'>
                            <Col className='col-12 col-lg-6 order-2 order-md-1'>
                                    <CardService />                                
                            </Col>
                            <Col className='col-12 col-lg-6 order-1 order-md-2'>
                                <Row className='w-100 p-0 m-0 justify-content-center'>
                                    <Col className='col-10'>
                                        <p className='textContentWhite'>
                                            Diseño personalizado para tu empresa o emprendimiento con funcionalidades únicas, cuéntanos sobre tu proyecto: 
                                        </p>
                                    </Col>
                                    <Col className='col-12'>
                                        <FormContact2 />
                                    </Col>
                                </Row>                            
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className='my-5'></div>
                {/* brochure */}
                <Row className='w-100 p-0 m-0 justify-content-center'>
                    <Col className='col-11 col-md-6 text-center'>
                        <p className='textContentWhite'>
                            En <strong>Enlace Web Design</strong>, estamos comprometidos en ofrecer servicios de diseño y programación web personalizados y de alta calidad para crear soluciones web innovadoras que sean estéticamente atractivas, fáciles de usar y funcionales.
                        </p>
                        <div className='my-5'></div>
                        <h2 className='textContentWhite'>
                            Aplicaciones Desarrolladas para crecer
                        </h2>
                        <div className='my-5'></div>
                        <a style={{ textDecoration: "none"}} href='https://enlacewebdesign.com/portafolio/brochure_enlace-web-design-es-2024.pdf' className='buttonGhostYellow' target='_blank'>Descarga nuestro brochure</a>
                        {/* <UIButton
                            class__button={'buttonGhostYellow'}
                            children={'Descarga nuestro brochure'}
                        /> */}
                        <p className='textContentWhite mt-5 px-5'>
                            Hoy en día existen diferentes tipos de desarrollos para sitios web responsive, así que tu cliente determinará la plataforma y el marco de trabajo que más te convenga:
                        </p>
                    </Col>
                    <Col className='col-12 col-md-10 px-0 py-5 m-0'>
                        <SliderItems />
                    </Col>
                </Row>
                <Row className='w-100 p-0 m-0 justify-content-center'>
                    <Col className='col-12 col-md-6 containerCardTest'>
                        <img className='robotSearch' src={robotSearch} alt='robot search'></img>
                        <img className='robotDog' src={robotDog} alt='robot dog'></img>
                        <CardTest />
                    </Col>
                </Row>
                {/* <Row className='w-100 px-0 py-5 m-0 justify-content-center'>
                    <Col className='col-10 text-center'>
                        <h2 className='txtWhite'>
                            Conoce Nuestros Proyectos
                        </h2>
                        <SliderClients />
                    </Col>
                </Row> */}
                <Row className='w-100 px-0 py-5 m-0 justify-content-center'>
                    <Col className='col-12 col-md-10'>
                        <Row className='w-100 px-0 py-5 m-0 justify-content-center'>
                            <Col className='col-12 col-md-7'>
                                <CardNewsLetter />
                            </Col>
                            <Col className='col-12 col-md-5 justify-content-center'>
                                <Row className='w-100 p-0  m-0 justify-content-center'>
                                    <Col>
                                        <Row className='w-100 p-0 m-0 justify-content-center align-items-center'>
                                            <Col className='col-2'>
                                                <img src={youtube} width={60} height={60} alt='youtube '/>
                                            </Col>
                                            <Col className='col-auto col-md-10 pl-5'>
                                                <a target='_blank' href='https://www.youtube.com/channel/UCeoFNiuoujEk6IQa_ukL2xw' className='txtWhite linkRedes'>
                                                    @enlacewebdesign
                                                </a>
                                            </Col>
                                        </Row>
                                        <Row className='w-100 p-0 m-0 justify-content-center align-items-center'>
                                            <Col className='col-2'>
                                                <img src={twitter} width={60} height={60} alt='twitter'/>
                                            </Col>
                                            <Col className='col-auto col-md-10 pl-5'>
                                                <a target='_blank' href='https://twitter.com/EnlaceWebcol' className='txtWhite linkRedes'>
                                                    @EnlaceWebcol
                                                </a>
                                            </Col>
                                        </Row>
                                        <Row className='w-100 p-0 m-0 justify-content-center align-items-center'>
                                            <Col className='col-2'>
                                                <img src={linkedin} width={60} height={60} alt='linkedin'/>
                                            </Col>
                                            <Col className='col-auto col-md-10 pl-5'>
                                                <a target='_blank' href='https://www.linkedin.com/in/enlace-web-design-708479246/' className='txtWhite linkRedes'>
                                                    @enlacewebdesign
                                                </a>
                                            </Col>
                                        </Row>                                        
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div>
                    {/* <InfographicResponsive /> */}
                    {/* informacion */}
                    {/* <Row className='justify-content-center p-0 m-0' id='cotizador'>                    
                        <Col className='col-10 col-md-8 col-lg-7 text-center contCotizador'>
                            <Row className='justify-content-center align-items-center'>
                                <Col className='col-12 text-center'>
                                    <h1>
                                        Aplicaciones Desarrolladas para crecer
                                    </h1>
                                    <p>
                                        Hoy en día existen diferentes tipos de desarrollos para aplicaciones móviles, así que tu cliente determinará la plataforma que más te convenga, 
                                    </p>
                                </Col>
                                <Col className='col-12 col-xl-8'>
                                    <Row className='g-2'>
                                        <Col className='col-6 col-md-3'>
                                            <Card className='cardServicios'>
                                                <img width={70} height={70} alt="iconServicios" className='iconServicios' src={iconServicios1}></img>
                                                <p className='titServicios'>
                                                    Diseño
                                                </p>
                                                <p className='txtServicios'>
                                                    Personlizado
                                                </p>
                                            </Card>
                                        </Col>
                                        <Col className='col-6 col-md-3'>
                                            <Card className='cardServicios'> 
                                                <img width={70} height={70} alt="iconServicios" className='iconServicios' src={iconServicios2}></img>
                                                <p  className='titServicios'>
                                                    Ubicación
                                                </p>
                                                <p className='txtServicios'>
                                                    Geolocalizada
                                                </p>
                                            </Card>
                                        </Col>
                                        <Col className='col-6 col-md-3'>
                                            <Card className='cardServicios'> 
                                                <img width={70} height={70} alt="iconServicios" className='iconServicios' src={iconServicios3}></img>
                                                <p  className='titServicios'>
                                                    Tienda
                                                </p>
                                                <p className='txtServicios'>
                                                    Marketplace
                                                </p>
                                            </Card>
                                        </Col>
                                        <Col className='col-6 col-md-3'>
                                            <Card className='cardServicios'> 
                                                <img width={70} height={70} alt="iconServicios" className='iconServicios' src={iconServicios4}></img>
                                                <p  className='titServicios'>
                                                    Soporte
                                                </p>
                                                <p className='txtServicios'>
                                                    y contacto
                                                </p>
                                            </Card>
                                        </Col>
                                        <Col className='col-6 col-md-3'>
                                            <Card className='cardServicios'> 
                                                <img width={70} height={70} alt="iconServicios" className='iconServicios' src={iconServicios5}></img>
                                                <p  className='titServicios'>
                                                    Formularios
                                                </p>
                                                <p className='txtServicios'>
                                                    Encuestas
                                                </p>
                                            </Card>
                                        </Col>
                                        <Col className='col-6 col-md-3'>
                                            <Card className='cardServicios'> 
                                                <img width={70} height={70} alt="iconServicios" className='iconServicios' src={iconServicios6}></img>
                                                <p  className='titServicios'>
                                                    Almacenamiento
                                                </p>
                                                <p className='txtServicios'>
                                                    Bases de datos
                                                </p>
                                            </Card>
                                        </Col>
                                        <Col className='col-6 col-md-3'>
                                            <Card className='cardServicios'> 
                                                <img width={70} height={70} alt="iconServicios" className='iconServicios' src={iconServicios7}></img>
                                                <p  className='titServicios'>
                                                    Roles
                                                </p>
                                                <p className='txtServicios'>
                                                    Usuarios
                                                </p>
                                            </Card>
                                        </Col>
                                        <Col className='col-6 col-md-3'>
                                            <Card className='cardServicios'> 
                                                <img width={70} height={70} alt="iconServicios" className='iconServicios' src={iconServicios8}></img>
                                                <p  className='titServicios'>
                                                    Reportes
                                                </p>
                                                <p className='txtServicios'>
                                                    Administrativos
                                                </p>
                                            </Card>
                                        </Col>
                                        <Col className='col-12'>
                                            <a
                                                className='btAmarillo'
                                                href="https://enlacewebdesign.com/portafolio/brochure-enlace-web-design-es-23.pdf"
                                                target='_blank'
                                            >
                                                Descarga nuestro brochure
                                            </a>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row> */}
                    {/* informacion */}
                    {/* <Row className='justify-content-center p-0 m-0' id='cotizador'>
                        <Col className='col-10 col-md-8 col-lg-7 text-center contCotizador'>
                            <Row className='justify-content-center'>
                                <Col className='col-12 col-lg-5'>
                                    <img className='imgMobile' src={imgMobile} alt='Imagen Mobile' />
                                </Col>
                                <Col className='col-12 col-lg-7 text-left'>
                                    <p>
                                        Cómo será tu proyecto:
                                    </p>
                                    <p>
                                        Prototipado:
                                    </p>
                                    <p>
                                        Prototipado y maquetación en html y css3, Frameworks como Angular o React
                                    </p>
                                    <p>
                                        Testing:
                                    </p>
                                    <p>
                                        Realizamos pruebas y ajustes que surjan en la experiencia del usuario.
                                    </p>
                                    <p>
                                        Administración:
                                    </p>
                                    <p>
                                        Queremos que tu proyecto crezca, así que siempre estamos disponibles para suministrar soporte y también para implementar mejoras en tu producto
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>   */}
                    {/* experiencia */}
                    {/* <Row className='justify-content-center contUx text-center' id='experiencia'>
                        <Col className='col-10 col-md-8 col-lg-6 p-0 m-0'>
                            <img width={868} height={842} alt="imgExperiencia" className='imgExperiencia' src={imgExperiencia}></img>
                            <p className='titExperiencia'>
                                Diseño de experiencia de usuario
                            </p>
                            <p className='txtExperiencia'>
                                Te guíamos a traves de la experiencia de tu cliente en tu aplicación o sitio web, usamos las técnicas de diseño centrado en el usuario para crear aplicaciones con valor y usabilidad únicas de tu modelo de negocio.
                            </p>
                        </Col>
                        <Col className='col-12'></Col>
                        <Col className='col-10 col-md-8 col-lg-6 p-0 m-0'>
                            <p className='titExperiencia'>
                                Proyectos que generan valor a tu organización:
                            </p>
                        </Col>
                        <Col className='col-12'></Col>
                        <Col className='col-10 col-md-8 col-lg-6'>
                            <Row className='p-0 m-0 g-3'>
                                <Col className='col-6 col-md-3'>
                                    <img width={136} height={136} className='imgUx' src={imgUx1} alt='Img Ux 1' />
                                </Col>
                                <Col className='col-6 col-md-3'>
                                    <img width={136} height={136} className='imgUx' src={imgUx2} alt='Img Ux 2' />
                                </Col>
                                <Col className='col-6 col-md-3'>
                                    <img width={136} height={136} className='imgUx' src={imgUx3} alt='Img Ux 3' />
                                </Col>
                                <Col className='col-6 col-md-3'>
                                    <img width={136} height={136} className='imgUx' src={imgUx4} alt='Img Ux 4'/>
                                </Col>
                            </Row>
                        </Col>
                    </Row> */}
                    {/* seguridad */}
                    {/* <Row className='w-100 m-0 justify-content-center text-center py-2 '>
                        <Col className='col-10 col-md-7 col-xl-6'>
                            <Row className=' justify-content-center align-items-center'>
                                <Col className='col-12 col-md-6'>
                                    <h2>
                                        Sistemas de seguridad cerrado
                                    </h2>
                                    <p>
                                        En nuestro desarollo ofrecemos sistemas de seguridad cerrados con su propio ecosistema, con tecnologías de punta en base de datos y manejo de la información.
                                    </p>
                                </Col>
                                <Col className='col-12 col-md-6'>
                                    <img width={284} height={238} className='imgSeguridad' src={imgSeguridad} alt='Galeria de seguridad 9'/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className='justify-content-center align-items-center p-0 m-0 w-100'>
                        <Col className='col-12 text-center'>
                            <h2>
                                Nuevos lenguajes y herramientas de programación
                            </h2>
                        </Col>
                        <Col className='col-12 col-md-10 p-0 m-0'>
                            <SliderItems />
                        </Col>
                        <Col className='col-12 text-center'>
                            <p>
                                Nunca fué tan fácil integrar las diversas plataformas como ahora
                            </p>
                        </Col>
                    </Row> */}
                    {/* <Contacto /> */}
                </div>
            </Container>
        </>
    )
}