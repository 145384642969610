import React from 'react';

import '../Contacto/Contacto.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Iframe from 'react-iframe';
 
import youtube from "../../assets/img/webp/youtube.webp"; 
import linkedin from "../../assets/img/webp/linkedin.webp"; 
import twitter from "../../assets/img/webp/twitter.webp"; 
import { FormContact2 } from '../../component/form/FormContact2';
import { Card } from 'react-bootstrap';
import ContactButtons from '../../component/contactButtons/ContactButtons';
 
 
export default function Contacto() {

    return (
        <>
            <Container fluid className='p-0'>
                <Row className='justify-content-center contContacto text-center m-0' id='contacto'>
                    <Col className='col-10 col-md-8 col-lg-8'>
                        <h3 className='titContacto'>
                            Contáctanos
                        </h3>
                        <Row className='justify-content-center my-5 p-0 m-0'>
                            <Col className='col-12 col-lg-4'>
                                <h3 className='titContacto'>
                                    Línea de atención:
                                </h3>
                                <h4 className='txtWhite'>
                                    Comunícate con nuestro equipo de soporte al cliente para resolver tus dudas
                                </h4>
                                <a className='linkContacto my-5' href='mailto:soporte@Enlace Web Design.com.co'>
                                    soporte@enlacewebdesign.com
                                </a>
                                <Row className='justify-content-center mt-5'>
                                    <h4 className='txtWhite'>
                                        Únete a nuestras redes sociales:
                                    </h4>
                                    <Col className='col-auto'>
                                        <Row >
                                            <Col className='col-2'>
                                                <img width="30" height="30" alt="youtube" className='imgRedes' src={youtube}></img>
                                            </Col>
                                            <Col className='col-auto'>
                                                <p className='txtRedes'>
                                                    @enlacewebdesign
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col className='col-2'>
                                                <img width="30" height="30" alt="twitter" className='imgRedes' src={twitter}></img>
                                            </Col>
                                            <Col className='col-auto'>
                                                <p className='txtRedes'>
                                                    @EnlaceWebcol
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col className='col-2'>
                                                <img width="30" height="30" alt="linkedin" className='imgRedes' src={linkedin}></img>
                                            </Col>
                                            <Col className='col-auto'>
                                                <p className='txtRedes'>
                                                    @enlace-web-design-col
                                                </p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className='col-12 col-lg-8 p-0'>
                                <Card className='cardFormContacto'>
                                    <FormContact2 />
                                </Card>
                                <Row className='mt-5'>
                                    <Col className='containerMap'>
                                        <p className='txtRedes'>
                                            Dónde nos ubicamos:
                                        </p>
                                        <Iframe 
                                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3976.289341413644!2d-74.0464634851724!3d4.719721542806339!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f85448c9655ad%3A0x9c627d3018320de0!2zQ29udGFkb3IsIFVzYXF1w6luLCBCb2dvdMOh!5e0!3m2!1ses!2sco!4v1674012062705!5m2!1ses!2sco" 
                                            width="300" 
                                            height="200" 
                                            style={{border:"0",}} 
                                            allowfullscreen="" 
                                            loading="lazy" 
                                            referrerpolicy="no-referrer-when-downgrade" 
                                        />                                    
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
}