import React from 'react';

import '../Design/Design.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'primereact/button';
import { Card } from 'react-bootstrap';
 
import icoHtml from "../../assets/img/webp/logo-html.webp";
import icoReact from "../../assets/img/webp/logo-react.webp";
import imgResponsive from "../../assets/img/webp/banner-guias.webp";
import ContactoServicio from '../../component/contacto/ContactoServicio';
import ContactButtons from '../../component/contactButtons/ContactButtons';

export default function Design() {

    return (
        <>
            <Container fluid className='bgServicio text-center'> 
                <ContactButtons />
                <Row className=' w-100 p-0 m-0 justify-content-center'>
                    <Col className='col-12 col-md-6'>
                        <h1 className='titServicio'>
                            Sitios web adaptables a dispositivos móviles
                        </h1>
                        <p className='txtServicio'>
                            Html 5 y React
                        </p>                    
                    </Col>                    
                    <Col className='col-12'>
                        <Row className=' w-100 p-0 m-0 justify-content-center'>
                            <Col className='col-auto'>
                                <img width={84} height={103} className='imgResponsive' alt="icoHtml" src={icoHtml}></img>
                            </Col>
                            <Col className='col-auto'>
                                <img width={100} height={100} className='imgResponsive' alt="icoReact" src={icoReact}></img>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className=' w-100 p-0 m-0 justify-content-center'>
                    <Col className='col-11 col-md-11'>
                        <Row className=' w-100 p-0 m-0 justify-content-center align-items-center'>                            
                            <Col className='col-12 col-lg-6 order-2 order-lg-1'>
                                <img width={445} height={458}  alt="imgResponsive" className='imgResponsive' src={imgResponsive}></img>
                                <a
                                    className='btAmarillo'
                                    href="https://enlacewebdesign.com/portafolio/brochure-enlace-web-design-es-23.pdf"
                                    target='_blank'
                                >
                                    Descarga nuestro brochure
                                </a>
                                <p className='txtServicio mt-3'>
                                    Encuentra más información sobre desarrollo de sitios web responsive y que características puedes ofrecerle a tus clientes para tener la mejor experiencia
                                </p>
                            </Col>
                            <Col className='col-12 col-lg-6 order-1 order-lg-2'>
                                <Row className=' w-100 p-0 m-0 justify-content-center align-items-center'>
                                    <Col className='col-12'>
                                        <h2 className='txtServicio'>
                                            Sitios web pensados para crecer
                                        </h2>
                                        <h2 className='subTitServicio'>
                                            Landing Pages
                                        </h2>
                                        <p className='txtServicio'>
                                            Páginas especializadas para una campaña o producto, se caracterizan por ser la página que tus usuarios visitan cuando hacen click en alguna publicidad externa al sitio y son dirigidos a esta página que está enfocada en conversiones y  cumplimiento de objetivos de marketing
                                        </p>
                                    </Col>
                                    <Col className='col-12'>
                                        <h2 className='subTitServicio'>
                                            Sitios Web Html5 y Css3
                                        </h2>
                                        <p className='txtServicio'>
                                            Páginas super rápidas que sirven de vitrina para tu negocio, son sitios web que por estar realizados sólo con html cargan la información sin ningún tipo de limitación esto es especialmente importante para sitios web  enfocados en presentar la imagen de la empresa.
                                        </p>
                                    </Col>
                                    <Col className='col-12'>
                                        <h2 className='subTitServicio'>
                                            Sitios Web React y Angular
                                        </h2>
                                        <p className='txtServicio'>
                                            Funcionalidades únicas para tu negocio, desde interacciones hasta animaciones para hacer la experiencia única que tu cliente está esperando, comunicación con servicios externos de proveedores para enriquecer los servicios que puedes prestar en tu sitio web. 
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className=' w-100 p-0 m-0 justify-content-center'>
                    <Col className='col-12 col-md-5'>
                        
                    </Col>
                </Row>
                <Row className=' w-100 p-0 m-0 justify-content-center mt-5'>
                    <Col className='col-12 col-lg-8'>
                        <ContactoServicio />
                    </Col>
                </Row>
                <Row className=' w-100 p-0 m-0 justify-content-center'>
                    <Col className='col-12 col-lg-8'>
                        <Card className='cardInformacion'>
                            <Row>
                                <Col className='col-12 col-md-6'>
                                    <h2 className='titServicio'>
                                        Más información
                                    </h2>
                                    <p className='txtServicio'>
                                        Sobre Responsive Design
                                    </p>
                                    <p className='txtServicio'>
                                        Conoce cuál es la tendencia de desarrollo de sitios web para que sean visibles en todos los dispositivos móviles.
                                    </p>
                                </Col>
                                <Col className='col-12 col-md-6'>
                                    <Button className='btAmarillo'>
                                        Guías de Diseño
                                    </Button>
                                    <p className='txtServicio mt-3'>
                                        Conoce cómo puedes ver si tu sitio web se visualiza adecuadamente en dispositivos móviles
                                    </p>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}