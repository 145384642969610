import React from 'react';
import { useNavigate } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import imgItem1 from '../../assets/img/webp/img-slider-001.webp';
import imgItem2 from '../../assets/img/webp/img-slider-002.webp';
import iconAndroid from "../../assets/img/webp/logo-android.webp";
import iconIos from "../../assets/img/webp/logo-ios.webp";
import iconReact from "../../assets/img/webp/logo-react.webp";
import iconHtml from "../../assets/img/webp/logo-html.webp";

import "../slider/Slider.css";
import UIButton from '../atoms/UIButton/UIButton';

export default function Slider() {
    const navigate = useNavigate();
    const goResponsive = () => navigate("/sitios-web-responsive");
    const goWordpress = () => navigate("/wordpress");
    return (
        <>
            <Carousel>
                <Carousel.Item className='contItem' interval="888000">
                    <Row className="align-items-center justify-content-center p-0 m-0">
                        <Col className="col-12 col-md-5 col-lg-4 col-xl-4 ">
                            <div className="txtItemCarousel text-center">
                                {/* <Row className='justify-content-center align-items-center'>
                                    <Col className='col-auto'>
                                        <img width={86} height={100} className='iconAndroid' src={iconAndroid} alt='IconoAndroid' />
                                    </Col>
                                    <Col className='col-auto'>
                                        <img width={100} height={100} className='iconIos' src={iconIos} alt='IconoIos' />
                                    </Col>
                                </Row> */}
                                <h1 className='titSlider'>React y Html5</h1>
                                <h2 className='subTitSlider'>Sitios Web Responsive </h2>
                                <p className='txtSlider'>Tu carta de presentación para tus clientes, sitios ligeros de gran velocidad</p>
                                <br />
                                <UIButton 
                                    class__button={'buttonVistaPrevia'}
                                    children={'Conoce un sitio react'}
                                    action={goResponsive}
                                >
                                </UIButton>
                            </div>
                        </Col>
                        <Col className="col-12 col-md-5 col-lg-4 col-xl-4  text-center">
                            <img width={530} height={424} className="imgItem1" src={imgItem1} alt='Imagen Item 1' />
                        </Col>
                    </Row>
                </Carousel.Item>
                <Carousel.Item className='contItem' interval="888000">
                    <Row className="align-items-center justify-content-center p-0 m-0">
                        <Col className="col-12 col-md-5 col-lg-4 col-xl-4">
                            <div className="txtItemCarousel text-center">
                                {/* <Row className='justify-content-center align-items-center'>
                                    <Col className='col-auto'>
                                        <img width={86} height={100} className='iconAndroid' src={iconReact} alt='Icono Android' />
                                    </Col>
                                    <Col className='col-auto'>
                                        <img width={86} height={100} className='iconIos' src={iconHtml} alt='Icono Ios' />
                                    </Col>
                                </Row> */}
                                <h1 className='titSlider'>Word Press</h1>
                                <h2 className='subTitSlider'>Sitios web administrables</h2>
                                <p className='txtSlider'>Temas personalizables y plugins para funcionalidades extra, blogs, ecommerce, sitios empresariales para ventas online.</p>
                                <br />
                                <a href='https://enlacewebdesign.com/ecommerce/' className='buttonVistaPrevia' target='_blank'>
                                    Conoce un sitio web Word Press
                                </a>
                                {/* <UIButton 
                                    class__button={'buttonVistaPrevia'}
                                    children={'Vista previa'}
                                    action={goWordpress}
                                >
                                </UIButton> */}
                            </div>
                        </Col>
                        <Col className="col-12 col-md-5 col-lg-4 col-xl-4 text-center">
                            <img width={530} height={424} className="imgItem2" src={imgItem2} alt='Imagen Item' />
                        </Col>
                    </Row>
                </Carousel.Item>
            </Carousel>
        </>
    )
}

