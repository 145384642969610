import React from 'react';

import '../Guias/Guias.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Slider from '../../component/slider/Slider';
 
 
export default function Guias() {

    return (
        <>
            <Container fluid className='p-0'>
                {/* carousel */}
                <Row className='justify-content-center contCarousel p-0 m-0' id='carousel'>
                    <Col className='col-12 mb-3'>
                        <Slider />
                    </Col>
                </Row>
            </Container>
        </>
    )
}