import { useRef } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./SliderItems.css";

import iconSeguridad001 from '../../assets/img/webp/icon-seguridad001.webp';
import iconSeguridad002 from '../../assets/img/webp/icon-seguridad002.webp';
import iconSeguridad003 from '../../assets/img/webp/icon-seguridad003.webp';
import iconSeguridad004 from '../../assets/img/webp/icon-seguridad004.webp';
import iconSeguridad005 from '../../assets/img/webp/icon-seguridad005.webp';
import iconSeguridad006 from '../../assets/img/webp/icon-seguridad006.webp';
import iconSeguridad007 from '../../assets/img/webp/icon-seguridad007.webp';
import iconSeguridad008 from '../../assets/img/webp/icon-seguridad008.webp';
import iconSeguridad009 from '../../assets/img/webp/icon-seguridad009.webp';
import iconSeguridad010 from '../../assets/img/webp/icon-seguridad010.webp';

function SliderItems() {
    const indexRef = useRef(0);

    const items = [
        { image: iconSeguridad001, text: 'JavaScript' },
        { image: iconSeguridad002, text: 'PHP' },
        { image: iconSeguridad003, text: 'Pyton' },
        { image: iconSeguridad004, text: 'Git' },
        { image: iconSeguridad005, text: 'Visual Code' },
        { image: iconSeguridad006, text: 'Visual Studio' },
        { image: iconSeguridad007, text: 'Node' },
        { image: iconSeguridad008, text: 'Java' },
        { image: iconSeguridad009, text: 'TypeScript' },
        { image: iconSeguridad010, text: 'WordPress' },
    ];

    const handleSlide = (slideIndex) => {
        indexRef.current = slideIndex;
    };

    return (
        <Carousel onSlide={handleSlide} variant="dark" className='SliderItems'>
            {[...Array(2)].map((_, index) => (
                <Carousel.Item key={index} interval={10000} className='SliderCarouselItem'>
                    <Row className="justify-content-center">
                        {items.slice(index * 5, index * 5 + 5).map((item, i) => (
                            <Col key={i} xs={4} md={2} lg={'auto'} className="text-center">
                                <img className="d-block w-100 " src={item.image} alt='image'/>
                                <p className="txtWhite">{item.text}</p>
                            </Col>
                        ))}
                    </Row>
                </Carousel.Item>
            ))}
        </Carousel>
    );
}

export default SliderItems;