import UIButton from "../atoms/UIButton/UIButton";
import "./ContactButtons.css";

function ContactButtons() {
  function callwhatsapp() {
    window.open("https://wa.me/3015752752", "_blank");
  }

  return (
    <>
      <div className="container__contact">
        <UIButton  
          class__button={'button__whatsapp'}
          action={callwhatsapp}
        />
      </div>
    </>
  );
}

export default ContactButtons;
