import React from 'react';

import '../footer/Footer.css';

import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';

export default function Footer() {
  return (
    <footer class="bg-black py-5">
       <Container fluid>
        <Row className='justify-content-between w-100 p-0 m-0'>
          <Col className='col-12 col-md-4'>
            <h5 className='txtFooter'>
              Desarrollamos aplicaciones para los emprendimientos de hoy en día, lenguajes modernos y adaptables en el tiempo para que tu empresa crezca.
            </h5>
          </Col>
          <Col className='col-12 col-md-4 text-center'>
            <h4 className='titFooter'>Menú</h4>
            <ul className='menuFooter'>
              <li>
                <a className='linkContacto' href='#carousel'>
                  Inicio
                </a>
              </li>
              <li>
                <a className='linkContacto' href='#cotizador'>
                  Portafolio
                </a>
              </li>
              <li>
                <a className='linkContacto' href='#contacto'>
                  Sobre Nosotros
                </a>
              </li>
              <li>
                <a className='linkContacto' href='#contacto'>
                  Contáctanos
                </a>
              </li>
            </ul>
          </Col>
          <hr style={{border: "1px solid white"}}></hr>
          <Col className='col-12 text-center'>
            <h5>
              <a href="/">
                Políticas y tratamiento de datos personales
              </a>
            </h5>
            <h5 className='txtCopyright'>
              Todos los derechos reservados Enlace Web Design 2023 ©
            </h5>
          </Col>
        </Row>
       </Container>
    </footer>
  )
}