import Card from 'react-bootstrap/Card';
import "./Card.css";
import { Col, } from 'react-bootstrap';
import UIButton from '../atoms/UIButton/UIButton';

function CardNewsLetter() {
    return (
        <Card className='cardContainer text-center'>
            <p className='txtWhite'>
                <strong>
                    ¿No te quieres perder de nada?
                </strong>
            </p>
            <p className='txtWhite'>
                Entérate de las últimas tecnologías y avances en diseño y desarrollo web:
            </p>
            <input className='text-center' type='mail' placeholder='Tu correo electrónico' />
            <div className='d-flex justify-content-center'>
                <Col className='col-auto mt-3'>
                    <UIButton
                        class__button={'buttonGhostYellow'}
                        children={'Visita nuestro blog de diseño'}
                    />                
                </Col>
            </div>
        </Card>
    );
}

export default CardNewsLetter;