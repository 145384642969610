import React from 'react';
import { Button } from 'react-bootstrap';

import './Header.css';

import logo from '../../assets/img/header/brand_positive.webp';

export default function Header(){
    return (
        <nav class="navbar navbar-expand-lg navbar-light fixed-top shadow-sm" id="mainNav">
            {/*INICIO HEADER  */}
            <div class="container px-5">
                <a class="navbar-brand fw-bold" href="/">
                    <img width={146} height={52} src={logo} className="logoClass" alt='logoHeader'/>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    <div className="iconMenu"></div>
                </button>
                <div class="collapse navbar-collapse" id="navbarResponsive">
                    <ul class="navbar-nav ms-auto me-4 my-3 my-lg-0">
                        <li class="nav-item"><a class="nav-link me-lg-3" href="/">Inicio</a></li>
                        {/* <li class="nav-item"><a class="nav-link me-lg-3" href="/guias">Guías de Diseño</a></li> */}
                        {/* <li class="nav-item"><a class="nav-link me-lg-3" href="/contacto">Sobre nosotros</a></li> */}
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle linkDropdown" href="/guias-de-diseno" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Servicios
                            </a>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="/aplicaciones">Aplicaciones</a></li>
                                <li><a class="dropdown-item" href="/sitios-web-responsive">Web Responsive</a></li>
                                <li><a class="dropdown-item" href="/wordpress">Word press</a></li>
                            </ul>
                        </li>
                    </ul>
                    <Button 
                        className='btAmarillo'
                        href="/contacto"                    
                    >
                        Contactanos                        
                    </Button>
                </div>
            </div>
        </nav>
    )
}
