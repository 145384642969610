import Card from 'react-bootstrap/Card';
import "./Card.css";
import { Col, Row } from 'react-bootstrap';
import UIButton from '../atoms/UIButton/UIButton';
import iconBeaker from "../../assets/img/icons/icon_beaker.webp";
import iconCog from "../../assets/img/icons/icon_cog.webp";
import iconGlobe from "../../assets/img/icons/icon_globe_alt.webp";

function CardTest() {
    return (
        <Card className='cardContainer'>
            <p className='txtWhite text-left'>
                <strong>
                    ¿Qué necesita tu empresa?
                </strong>
            </p>
            <p className='txtWhite text-left'>
                Herramientas para conocer cómo se comporta tu sitio web:
            </p>
            <div className='cardService'>
                <div className='p-1'>
                    <Row className='w-100 p-0 m-0 justify-content-center'>
                        <Col className='col-12 col-md-2 d-flex justify-content-center '>
                            <img whidt={60} height={60} src={iconBeaker} alt='icon beacker'></img>
                        </Col>
                        <Col className='col-12 col-md-9 text-left'>
                            <h3 className='txtWhite'>
                                <strong>
                                    Test responsive
                                </strong>
                            </h3>
                            <p className='txtWhite mb-0'>
                                Los sitios web “responsive” se adaptan al dispositivo en que están siendo visualizado, una buena experiencia de usuario requiere que tu sitio web siempre sea visible y sea visualmente acorde a tu marca.
                            </p>
                        </Col>
                        <Col className='col-12 col-md-1 d-flex justify-content-center align-items-center'>
                            <UIButton 
                                class__button={'buttonArrowRight'}
                            />
                        </Col>
                    </Row>
                </div>
                <hr className='separatorWhite' />
                <div className='p-1 mt-4'>
                    <Row className='w-100 p-0 m-0 justify-content-center'>
                        <Col className='col-12 col-md-2 d-flex justify-content-center '>
                            <img whidt={60} height={60} src={iconCog} alt='icon cog'></img>
                        </Col>
                        <Col className='col-12 col-md-9 text-left'>
                            <h3 className='txtWhite'>
                                <strong>
                                    Lighthouse
                                </strong>
                            </h3>
                            <p className='txtWhite mb-0'>
                                Un test de google para ver como está el “performance”, Accesabilidad, buenas prácticas y SEO, generando acciones para corregir posibles problemas con tu sitio web.
                            </p>
                        </Col>
                        <Col className='col-12 col-md-1 d-flex justify-content-center align-items-center'>
                            <UIButton 
                                class__button={'buttonArrowRight'}
                            />
                        </Col>
                    </Row>
                </div>
                <hr className='separatorWhite' />
                <div className='p-1 mt-4'>
                    <Row className='w-100 p-0 m-0 justify-content-center'>
                        <Col className='col-12 col-md-2 d-flex justify-content-center '>
                            <img whidt={60} height={60} src={iconGlobe} alt='icon globe'></img>
                        </Col>
                        <Col className='col-12 col-md-9 text-left'>
                            <h3 className='txtWhite'>
                                <strong>
                                    Google Analitycs y search console
                                </strong>
                            </h3>
                            <p className='txtWhite mb-0'>
                                Una plataforma de google para indexar tu sitio web en el buscador mas usado en el mundo, teniendo un panel de control para mirar el tráfico a tu sitio y establecer metas para tu equipo de marketing.
                            </p>
                        </Col>
                        <Col className='col-12 col-md-1 d-flex justify-content-center align-items-center'>
                            <UIButton 
                                class__button={'buttonArrowRight'}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
            <div className='d-flex justify-content-center'>
                <Col className='col-auto mt-3'>
                    <UIButton
                        class__button={'buttonGhostYellow'}
                        children={'Visita nuestro blog de diseño'}
                    />                
                </Col>
            </div>
        </Card>
    );
}

export default CardTest;