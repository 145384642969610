import Button from 'react-bootstrap/Button';
import "./UIButton.css"

function UIButton({class__button,children,action,}) {
  return (
    <>
      <Button bsPrefix={class__button} onClick={() => action()}>{children}</Button>{' '}
    </>
  );
}

export default UIButton;