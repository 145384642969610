import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./../App.css"
import Home from '../views/home/home';
import Guias from '../views/Guias/Guias';
import Design from '../views/Design/Design';
import Contacto from '../views/Contacto/Contacto';
import Aplicaciones from '../views/Aplicaciones/Aplicaciones';
import Wordpress from '../views/Wordpress/Wordpress';

const Rutas = () => {

    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Home />}></Route>
                    <Route path='/aplicaciones' element={<Aplicaciones />}></Route>
                    <Route path='/contacto' element={<Contacto />}></Route>
                    <Route path='/guias-de-diseno' element={<Guias />}></Route>
                    <Route path='/sitios-web-responsive' element={<Design />}></Route>
                    <Route path='/wordpress' element={<Wordpress/>}></Route>
                    <Route path='/*' element={<Home />}></Route>
                </Routes>
            </BrowserRouter >
        </div >
    )
}


export default Rutas;