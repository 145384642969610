import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';

import "./FormContact.css";
import UIButton from '../atoms/UIButton/UIButton';

export const FormContact2 = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_vbre7bb', 'template_enlacecontacto', form.current, '4jZrsoaqNbdUIQdqt')
            .then((result) => {
                console.log(result.text);
                setShowFormStart(false);
                setShowFormSend(true)
            }, (error) => {
                console.log(error.text);
            });
    };

    const [FormStart,setShowFormStart] = useState(true);
    const [FormSend,setShowFormSend] = useState(false);

    return (
        <>  
            {
                FormStart && (
                    <>
                        <form
                            ref={form}
                            onSubmit={sendEmail}
                            className="g-3 needs-validation"
                        >
                            <Row className='justify-content-center'>
                                <Col className='col-12 col-md-6'>
                                    <label
                                        for="validationCustom01"
                                        className="formLabel"
                                    >
                                        Nombre
                                    </label>
                                    <input
                                        id="validationCustom01"
                                        required
                                        type="text"
                                        name="user_name"
                                        className="form-control"
                                        placeholder='Nombre y Apellido'
                                    />
                                    <div class="valid-feedback">
                                        Looks good!
                                    </div>
                                    <div class="invalid-feedback">
                                        Please choose a username.
                                    </div>
                                </Col>
                                <Col className='col-12 col-md-6'>
                                    <label
                                        for="validationCustom03"
                                        class="formLabel"
                                    >
                                        Email
                                    </label>
                                    <input
                                        id="validationCustom03"
                                        required
                                        type="email"
                                        name="user_email"
                                        className="form-control"
                                        placeholder='correo@dominio.com'
                                    />
                                    <div class="valid-feedback">
                                        Looks good!
                                    </div>
                                    <div class="invalid-feedback">
                                        Please choose a username.
                                    </div>
                                </Col>
                                <Col className='col-12'>
                                    <label
                                        for="validationCustom04"
                                        class="formLabel"
                                    >
                                        Mensaje
                                    </label>
                                    <textarea
                                        id="validationCustom04"
                                        required
                                        name="message"
                                        className="form-control"
                                        placeholder='Déjanos tu mensaje'
                                    />
                                    <div class="valid-feedback">
                                        Looks good!
                                    </div>
                                    <div class="invalid-feedback">
                                        Please choose a username.
                                    </div>
                                </Col>
                                <Col className='col-12 text-center'>
                                    <button
                                        className='buttonGhostYellow'
                                        type="submit"
                                        value="Send"
                                    >
                                        Comienza tu proyecto
                                    </button>                                    
                                </Col>
                            </Row>
                        </form>
                    </>
                )
            }
            {
                FormSend && (
                    <>
                        <Row className='contFormSend'>
                            <Col className='col-6 contImgBuzon'>
                            </Col>
                            <Col className='col-6 contTxtBuzon'>
                                <div>
                                    <p className='titBuzon'>
                                        Tu mensaje ha sido enviado
                                    </p>
                                    <p className='txtBuzon'>
                                        Gracias por comunicarte con Enlace Web Design, tu proyecto está a punto de comenzar
                                    </p>                                                                    
                                </div>
                            </Col>
                        </Row>
                    </>
                )
            }
            
        </>
    )
}