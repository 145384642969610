import Card from 'react-bootstrap/Card';
import "./Card.css";
import { Col, Row } from 'react-bootstrap';
import UIButton from '../atoms/UIButton/UIButton';
import iconBeaker from "../../assets/img/icons/icon_beaker.webp";
import iconCog from "../../assets/img/icons/icon_cog.webp";

function CardService() {
    return (
        <Card className='cardContainer'>
            <p className='txtWhite text-left'>
                <strong>
                    Conoce más información sobre nuestros planes de diseño web:
                </strong>
            </p>
            <a href='#' style={{textDecoration: "none", }} target='_blank'>
                <div className='cardService p-1'>
                    <Row className='w-100 p-0 m-0 justify-content-around'>
                        <Col className='col-12 col-md-2 d-flex justify-content-center '>
                            <img width={60} height={60} src={iconBeaker} alt='icon beaker'></img>
                        </Col>
                        <Col className='col-12 col-md-8 text-left'>
                            <h3 className='txtWhite'>
                                <strong>
                                    Web Corporativa
                                </strong>
                            </h3>
                            <p className='txtWhite mb-0'>
                                Consigue posicionamiento, visibilidad y contacto con tu página corporativa.
                            </p>
                        </Col>
                        <Col className='col-12 col-md-auto d-flex justify-content-center align-items-end'>                        
                            <UIButton 
                                class__button={'buttonArrowRight'}
                            />
                        </Col>
                    </Row>
                </div>
            </a>
            <a href='https://enlacewebdesign.com/ecommerce/' style={{textDecoration: "none", }} target='_blank'>            
                <div className='cardService p-1 mt-4'>
                    <Row className='w-100 p-0 m-0 justify-content-around'>
                        <Col className='col-12 col-md-2 d-flex justify-content-center '>
                            <img width={60} height={60} src={iconCog} alt='icon cog'></img>
                        </Col>
                        <Col className='col-12 col-md-8 text-left'>
                            <h3 className='txtWhite'>
                                <strong>
                                    Ecommerce
                                </strong>
                            </h3>
                            <p className='txtWhite mb-0'>
                                Vende productos físicos y digitales con tu propia tienda on line administrable.
                            </p>
                        </Col>
                        <Col className='col-12 col-md-auto d-flex justify-content-center align-items-end'>
                            <UIButton 
                                class__button={'buttonArrowRight'}
                            />
                        </Col>
                    </Row>
                </div>
            </a>
        </Card>
    );
}

export default CardService;