import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import "./ContactoServicio.css";
import { Card } from "react-bootstrap";
import { FormContact2 } from "../form/FormContact2";

const ContactoServicio = () => {

    return (
        <>
            <Card className="cardContacto">
                <Row>
                    <Col className="col-12">
                        <h3>
                            Cotiza tu proyecto
                        </h3>
                        <p>
                            Diseño personalizado para tu empresa o emprendimiento con funcionalidades únicas.
                        </p>
                    </Col>
                    <Col className="col-12">
                        <FormContact2 />
                    </Col>
                </Row>
            </Card>            
        </>
    );
}

export default ContactoServicio;
