import React from 'react';

import '../Design/Design.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'primereact/button';
import { Card } from 'react-bootstrap';
 
import icoWordpress from "../../assets/img/webp/logo-wordpress.webp";
import ico007 from "../../assets/img/webp/icon-white-007.webp";
import ico008 from "../../assets/img/webp/icon-white-008.webp";
import ico009 from "../../assets/img/webp/icon-white-009.webp";
import buildWp from "../../assets/img/webp/build_wp.webp";
import ContactoServicio from '../../component/contacto/ContactoServicio';
import ContactButtons from '../../component/contactButtons/ContactButtons';
import robotApp4 from "../../assets/img/webp/robot_app4.webp";
import UIButton from '../../component/atoms/UIButton/UIButton';
import robotSearch from "../../assets/img/webp/robot_search.webp";
import robotDog from "../../assets/img/webp/robot_dog.webp";
import CardTest from '../../component/cards/CardTest';

export default function Wordpress() {

    return (
        <>
            <Container fluid className='bgServicio'> 
                <ContactButtons />
                <Row className='align-items-center justify-content-center bgMoon'>
                    <Col className='col-10'>
                        <Row className='align-items-center justify-content-center'>
                            <Col className='col-12 col-md-6'>
                                <img width={682} height={906} alt="icon" className='imgResponsive' src={buildWp}></img>
                            </Col>
                            <Col className='col-12 col-md-6'>
                                <p className='txtServicio'>
                                    Sitios Web
                                </p>
                                <Row className='align-items-center justify-content-center'>
                                    <Col className='col-2'>                            
                                        <img width={100} height={100} alt="icon" src={icoWordpress}></img>
                                    </Col>
                                    <Col className='col-10'>
                                        <h1 className='titServicio'>
                                            Word Press
                                        </h1>                            
                                    </Col>
                                </Row>
                                <p className='txtServicio'>
                                    CMS Administrables
                                </p>      
                                <p className='txtServicio mt-3'>
                                    Administradores de contenidos CMS que proporcionan facilidad en la administración de tu sitio web, con herramientas y plug in’s tanto pagos como gratuitos que incluídos en tu sitio web generan funcionalidades, roles de usuarios, integraciones con fuentes externas como api’s o pasarelas.
                                </p> 
                                {/* <a
                                    className='btAmarillo'
                                    href="https://enlacewebdesign.com/portafolio/brochure-enlace-web-design-es-23.pdf"
                                    target='_blank'
                                >
                                    Descarga nuestro brochure
                                </a>
                                <p className='txtServicio mt-3'>
                                    Conoce planes de Ecommerce, tiendas virtuales,  con las miles de opciones profesionales y gratuitas que ofrece este CMS tan popular en el mundo entero.
                                </p> */}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className='justify-content-center bgGray'>
                    <Col className='col-11 col-md-11'>
                        <Row className='containerDescription justify-content-center'>
                            <Col className='col-10'>
                                <Card className='cardDescription'>
                                    <Row className='justify-content-center aling-items-center'>
                                        <Col className='col-6'>
                                            <Row className='justify-content-center align-items-center textDescription'>
                                                <Col className='col-auto'>
                                                    <p className='txtServicio'>
                                                        ¿Qué es Word Press?
                                                    </p>
                                                    <p className='titServicio'>
                                                        Plataforma administrable
                                                    </p>
                                                    <p className='txtServicio'>
                                                        Proyectos robustos que tienen una gran cantidad de contenido como productos y servicios que deben ser actualizados cotidianamente, es lo ideal para multiples roles y sitios e’commerce.
                                                    </p>
                                                    <UIButton 
                                                        class__button={'btAmarillo'}
                                                        children={'Conoce un proyecto en react y html5'}
                                                    />        
                                                                                            
                                                    <UIButton 
                                                        class__button={'btAmarillo'}
                                                        children={'Descarga nuestro brochure'}
                                                    />                                                
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className='col-5'>
                                            <img src={robotApp4} className=''></img>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row className='w-100 p-0 m-0 justify-content-center'>
                            <Col className='col-12 col-md-6 containerCardTest'>
                                <img className='robotSearch' src={robotSearch} alt='robot search'></img>
                                <img className='robotDog' src={robotDog} alt='robot dog'></img>
                                <CardTest />
                            </Col>
                        </Row>
                        <Row className='justify-content-center align-items-start'>                            
                            <Col className='col-12 col-md-4'>
                                <img width={72} height={72} alt="icon" className='icoAplicacion' src={ico007}></img>
                                <h2 className='subTitServicio'>
                                    Roles de usuarios
                                </h2>
                                <p className='txtServicio'>
                                    Administra tus productos y servicios a través de tus colaboradores, roles de publicación y edición para siempre tener control sobre los cambios realizados.
                                </p>
                            </Col>
                            <Col className='col-12 col-md-4'>
                                <img width={72} height={72} alt="icon" className='icoAplicacion' src={ico008}></img>
                                <h2 className='subTitServicio'>
                                    Diseño
                                </h2>
                                <p className='txtServicio'>
                                    Cientos de recursos gráficos para entregar al usuario una imagen profesional de tu compañía.
                                </p>
                            </Col>
                            <Col className='col-12 col-md-4'>
                                <img width={72} height={72} alt="icon" className='icoAplicacion' src={ico009}></img>
                                <h2 className='subTitServicio'>
                                    Plug In's
                                </h2>
                                <p className='txtServicio'>
                                    Herramientas y recursos externos de proveedores que permiten integraciones con servicios especializados.
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className='justify-content-center mt-5'>
                    <Col className='col-12 col-lg-8'>
                        <ContactoServicio />
                    </Col>
                </Row>
                <Row className='justify-content-center'>
                    <Col className='col-12 col-lg-8'>
                        <Card className='cardInformacion'>
                            <Row>
                                <Col className='col-12 col-md-6'>
                                    <h2 className='titServicio'>
                                        Más información
                                    </h2>
                                    <p className='txtServicio'>
                                        Sobre Wordpress
                                    </p>
                                    <p className='txtServicio'>
                                        Tips de herramientas avanzadas y sugerencias para tu web que puedes utilizar en tu sitio web
                                    </p>
                                </Col>
                                <Col className='col-12 col-md-6'>
                                    <Button className='btAmarillo'>
                                        Guías de Diseño
                                    </Button>
                                    <p className='txtServicio mt-3'>
                                        Conoce cómo puedes ver si tu sitio web se visualiza adecuadamente en dispositivos móviles
                                    </p>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}