import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import Header from './component/header/Header';
import Footer from './component/footer/Footer';

import Rutas from './Router/Rutas';

export const App = () => {
  return (
    <div className="App">
      <Header />
      <Rutas />
      <Footer />
    </div>
  );
}

export default App;
