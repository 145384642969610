import React from 'react';

import '../Aplicaciones/Aplicaciones.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'primereact/button';
import { Card } from 'react-bootstrap';
 
import icoAndroid from "../../assets/img/webp/logo-android.webp";
import icoIos from "../../assets/img/webp/logo-ios.webp";
import ico001 from "../../assets/img/webp/icon-white-001.webp";
import ico002 from "../../assets/img/webp/icon-white-002.webp";
import ico003 from "../../assets/img/webp/icon-white-003.webp";
import ico004 from "../../assets/img/webp/icon-white-004.webp";
import ico005 from "../../assets/img/webp/icon-white-005.webp";
import ico006 from "../../assets/img/webp/icon-white-006.webp";
import imgAplicaciones from "../../assets/img/webp/robot_apps.webp";
import imgAplicaciones2 from "../../assets/img/webp/robot_apps2.webp";
import ContactoServicio from '../../component/contacto/ContactoServicio';
import ContactButtons from '../../component/contactButtons/ContactButtons';

export default function Aplicaciones() {

    return (
        <>
            <Container fluid className='bgServicio text-center'> 
                <ContactButtons />
                <Row>
                    <Col className='col-12'>
                        <h1 className='titServicio'>
                            Aplicaciones
                        </h1>
                        <p className='txtServicio'>
                            Android y iOS
                        </p>                    
                    </Col>                    
                    <Col className='col-12'>
                        <Row className='justify-content-center'>
                            <Col className='col-auto'>
                                <img width={86} height={100} alt="icoAndroid" src={icoAndroid}></img>
                            </Col>
                            <Col className='col-auto'>
                                <img width={100} height={100} alt="icoIos" src={icoIos}></img>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className='justify-content-center'>
                    <Col className='col-8'>
                        <h2 className='subTitServicio'>
                            Escoge los servicios para tus clientes
                        </h2>
                        <p className='txtServicio'>
                            Arma tu aplicación basada en los servicios que tu cliente espera de tu negocio, ofreceles la mejor experiencia y destacate de tu competencia                            
                        </p>
                    </Col>
                </Row>
                <Row className='justify-content-center'>
                    <Col className='col-11 col-md-9'>
                        <Row className='justify-content-center align-items-center'>
                            <Col className='col-12 col-md-3 g-0'>
                                <Row>
                                    <Col className='col-12 my-5'>
                                        <img width={72} height={72} alt="icoAplicacion" className='icoAplicacion' src={ico001}></img>
                                        <h2 className='subTitServicio'>
                                            Geolocalización
                                        </h2>
                                        <p className='txtServicio'>
                                            Servicio de desplazamiento, seguimiento mediante mapas para transporte, domicilios y encargos.
                                        </p>
                                    </Col>
                                    <Col className='col-12 my-5'>
                                        <img width={72} height={72} alt="icoAplicacion" className='icoAplicacion' src={ico002}></img>
                                        <h2 className='subTitServicio'>
                                            Tienda Virtual
                                        </h2>
                                        <p className='txtServicio'>
                                            Productos y servicios virtuales o físicos administrados para tener control de tu stock y actualizarlos.
                                        </p>
                                    </Col>
                                    <Col className='col-12 my-5'>
                                        <img width={72} height={72} alt="icoAplicacion" className='icoAplicacion' src={ico003}></img>
                                        <h2 className='subTitServicio'>
                                            Bases de Datos
                                        </h2>
                                        <p className='txtServicio'>
                                        Seguimiento y almacenamiento de los datos que se consumen en tu aplicación con seguridad avanzada.
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className='col-12 col-md-6 g-0'>
                                <img width={562} height={771} alt="imgAplicaciones" className='imgAplicaciones' src={imgAplicaciones}></img>
                                <img width={562} height={771} alt="imgAplicaciones" className='imgAplicaciones' src={imgAplicaciones2}></img>
                            </Col>
                            <Col className='col-12 col-md-3 g-0'>
                                <Row>
                                    <Col className='col-12 my-5'>
                                        <img width={72} height={72} alt="icoAplicacion" className='icoAplicacion' src={ico004}></img>
                                        <h2 className='subTitServicio'>
                                            Agendamiento
                                        </h2>
                                        <p className='txtServicio'>
                                            Soporte y contacto con tu usuario a través de agendamiento para video cconferencias o vinculos externos.
                                        </p>
                                    </Col>
                                    <Col className='col-12 my-5'>
                                        <img width={72} height={72} alt="icoAplicacion" className='icoAplicacion' src={ico005}></img>
                                        <h2 className='subTitServicio'>
                                            Formularios
                                        </h2>
                                        <p className='txtServicio'>
                                            Conoce a tu cliente y encuentra un canal de comunicación directo mediante encuestas y regístro de usuarios.
                                        </p>
                                    </Col>
                                    <Col className='col-12 my-5'>
                                        <img width={72} height={72} alt="icoAplicacion" className='icoAplicacion' src={ico006}></img>
                                        <h2 className='subTitServicio'>
                                            Soporte
                                        </h2>
                                        <p className='txtServicio'>
                                            Seguimiento de performance de tu aplicación para tener siempre la mejor experiencia de usuario.
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className='justify-content-center'>
                    <Col className='col-12 col-md-5'>
                        <p className='txtServicio'>
                            Aplicaciones a tu estilo y al de tu empresa con diseños y funcionalidades unicas
                        </p>
                        <a
                            className='btAmarillo'
                            href="https://enlacewebdesign.com/portafolio/brochure-enlace-web-design-es-23.pdf"
                            target='_blank'
                        >
                            Descarga nuestro brochure
                        </a>
                    </Col>
                </Row>
                <Row className='justify-content-center mt-5'>
                    <Col className='col-12 col-lg-8'>
                        <ContactoServicio />
                    </Col>
                </Row>
                <Row className='justify-content-center'>
                    <Col className='col-12 col-lg-8'>
                        <Card className='cardInformacion'>
                            <Row>
                                <Col className='col-12 col-md-6'>
                                    <h2 className='titServicio'>
                                        Más información
                                    </h2>
                                    <p className='txtServicio'>
                                        Sobre aplicaciones
                                    </p>
                                    <p className='txtServicio'>
                                        Conoce más acerca de qué son las aplicaciones y cómo las empresas desarrollan sus propias apps para estar más cerca del usuario a través de dispositivos móviles.
                                    </p>
                                </Col>
                                <Col className='col-12 col-md-6'>
                                    <Button className='btAmarillo'>
                                        Guías de Diseño
                                    </Button>
                                    <p className='txtServicio mt-3'>
                                        Encuentra más información sobre desarrollo de aplicaciones y que características puedes ofrecerle a tus clientes para tener la mejor experiencia
                                    </p>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}